/* custom rules for toast */
.toastify-content {
  bottom: 0px !important;
  background: none !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.toastify--bottom-right {
  padding: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  min-height: 0px !important;
}

.chat-toaster {
  color: #fff;
  padding: 12px;
  font-size: 16px;
}

.chat-toaster-success {
  background: #09b198;
}

.chat-toaster-warn {
  background: #EA9436;
}

.chat-toaster-error {
  background: #e8006b;
}