.TSVideoCall > div:first-of-type  {
  position: inherit !important;
}

.TSVideoCall video {
  height: 20;
}

.media-controls-container, .media-controls-container > * {
  display: none;
  height: 20px;
}

body /deep/ .media-controls-container {
  display: none;
  height: 20px;
}