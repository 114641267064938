.p-HeightObserverProvider {
  left: 0;
  /* position: fixed; */
  top: 0;
  width: 100%;
}

.p-HeightObserverProvider-container {
  margin: 4px;
  position: relative;
}

.p-FadeWrapper {
  position: relative;
}

.p-Fade {
  --p-fadeEnterDuration: 0.4s;
  --p-fadeEnterDelay: 0s;
  --p-fadeExitDuration: 0.2s;
  --p-fadeExitDelay: 0s;
}

.Label {
  margin-bottom: var(--p-spacing1);
  font-size: var(--fontSizeSm);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.p-FieldLabel {
  display: block;
}

.p-Animatable .p-LinkAuth--input {
  transition: height 0.34s ease, opacity 0.4s ease 0.2s, background 0.15s ease, border 0.15s ease,
    box-shadow 0.15s ease, color 0.15s ease !important;
}

.p-Animatable .p-HeightObserver {
  transition: height var(--p-heightObserverTransitionDuration) ease
    var(--p-heightObserverTransitionDelay);
}

.p-LinkAuth {
  overflow: hidden;
  padding: 0 !important;
  position: relative;
}

.p-HeightObserver--delayIncrease {
  --p-heightObserverTransitionDelay: var(--p-outerFrameHeightIncreaseTransitionDelay);
}

.p-HeightObserver {
  --p-heightObserverTransitionDuration: var(--p-outerFrameHeightTransitionDuration);
  --p-heightObserverTransitionDelay: 0s;
  box-sizing: content-box;
}

.p-Animatable .p-LinkInputWrapper {
  transition: width 0.3s ease 0.2s;
}

.Input,
.p-FauxInput {
  padding: var(--p-spacing3);
  background-color: var(--colorBackground);
  border-radius: var(--borderRadius);
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
  border: 1px solid var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.p-LinkInput {
  background: none !important;
  border: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.p-Input-input {
  display: block;
  width: 100%;
}

.Input--invalid {
  color: var(--colorDanger);
  border-color: var(--colorDanger);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    0 0 0 1px var(--colorDanger);
}

.Input:focus,
.p-Input--focused {
  outline: var(--focusOutline);
  border-color: var(--p-colorPrimaryAlpha50);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    var(--focusBoxShadow);
}

input::-webkit-input-placeholder {
  color: rgb(135, 150, 163);
  opacity: 1;
}

input::-moz-placeholder {
  color: rgb(135, 150, 163);
  opacity: 1;
}

input:-ms-input-placeholder {
  color: rgb(135, 150, 163);
  opacity: 1;
}

input::placeholder {
  color: rgb(135, 150, 163);
  opacity: 1;
}

.Error {
  margin-top: var(--p-spacing1);
  color: var(--colorDanger);
  font-size: var(--fontSizeSm);
}

:root {
  --focusOutline: 0;
  --focusBoxShadow: 0 0 0 3px var(--p-colorPrimaryAlpha20),
    0 1px 1px 0 var(--p-colorBackgroundContrastAlpha08);
  --p-spacing1: 0.25rem;
  --p-spacing3: 0.75rem;
  --fontSizeSm: 13px;
  --borderRadius: 10px;
  --p-colorPrimaryAlpha20: hsla(210, 96%, 45%, 25%);
  --p-colorBackgroundContrastAlpha08: rgba(0, 0, 0, 0.08);
  --colorBackground: #fff;
  --colorDanger: #df1b41;
  --p-colorPrimaryAlpha50: hsla(210, 96%, 45%, 50%);
  --p-colorBackgroundDeemphasize10: #e6e6e6;
  --p-outerFrameHeightTransitionDuration: 0.35s;
  --p-outerFrameHeightIncreaseTransitionDelay: 0.02s;
}
