/* TODO: remove this file once PhoneInput has been replaced with A11yPhoneInput throughout the various apps */
.PhoneInput {
  width: 100%;
  height: 50px;
  color: #98a6b2;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1.42857143;
  text-align: left !important;
  left: 0 !important;
  margin-top: 25px !important;
  border-radius: 10px !important;
  border: 2px solid #c1dfdb !important;
  cursor: pointer;
  padding: 0 20px;
}
.PhoneInputInput {
  outline: none;
  border: none;
  width: 40px;
}
