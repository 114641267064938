/* custom rules for toast */
.toastify-content {
  bottom: 0px !important;
  background: none !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.Toastify__toast-container--bottom-right {
  padding: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  min-height: 0 !important;
}

.Toastify__toast--default {
  background: #e8006b;
}

.Toastify__toast-body {
  color: #fff;
  padding: 12px;
  font-size: 16px;
}

.toaster-success {
  background: #09b198;
}

.toaster-warn {
  background: #ea9436;
}

.toaster-error {
  background: #e8006b;
}

/* override styles for DS toast rendered by useToastify */
.Toastify__toast-container:has(.ts-toast) {
  padding: 0;
  min-width: 256px;
  width: unset;
  top: max(calc(var(--sat) + 24px), 90px);
}

.Toastify__toast-container--top-center:has(.ts-toast) {
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0;
}

.ts-toast.Toastify__toast {
  padding: 0;
  border-radius: 12px;
  background-color: unset;
}

.ts-toast {
  padding: 0;
  min-height: 52px !important;
  box-shadow: 0px 2px 12px rgba(21, 17, 16, 0.2);
}

.ts-toast-body {
  padding: 0;
}
