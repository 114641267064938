html,
body,
#root {
  overscroll-behavior-y: none;
}

body {
  margin: 0;
}
/*
  TODO: css-0 is an empty hoc styled component, which can have a height if the line-height is default
*/
.css-0 {
  line-height: 0;
}
