:root {
  /* country dropdown border color */
  --PhoneInputCountryFlag-borderColor: white !important;
  /* country dropdown border and indicator focus color */
  --PhoneInput-color--focus: #596e92 !important;
}

/* Emotion styling doesn't seem to accept textAlign: 'left !important' and it seems necessary to override react-phone-number-input's styling */
.PhoneInput {
  text-align: left !important;
}

.PhoneInputInput {
  outline: none;
  border: none;
  width: 40px;
}

.PhoneInputInput::placeholder {
  color: #596e92;
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
}
